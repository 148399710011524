import $ from 'jquery';

$(document).ready(function () {
  initializeStepButton();
});

// Trigger initialization on window resize
$(window).resize(function () {
  initializeStepButton();
});

function initializeStepButton() {
  // Check if the window width is greater than 1024px
  if ($(window).width() > 1024) {
    onClickStepButton();
  } else {
    // Remove click event handlers for smaller screens
    $('.steps-widget__button-down-container').off('click');
    $('.steps-widget__button-down-container').each(function () {
      const parentRow = $(this).parent();
      // Set initial height based on the image height
      const initRowHeight = parentRow
        .find('.steps-widget__image')
        .outerHeight(true);
      parentRow.css('height', initRowHeight);
      // Remove 'active' class and reset the visibility of title and content
      parentRow.removeClass('active');
      parentRow.find('.steps-widget__title').show();
      parentRow.find('.steps-widget__hide-content').hide();
    });
  }
}

function onClickStepButton() {
  const stepButtons = $('.steps-widget__button-down-container');
  if (!stepButtons.length) return;

  // Remove existing click event handlers
  stepButtons.off('click');

  stepButtons.each(function () {
    const parentRow = $(this).parent();
    // Set initial height based on the image height
    const initRowHeight = parentRow
      .find('.steps-widget__image')
      .outerHeight(true);
    parentRow.css('height', initRowHeight);

    $(this).on('click', function () {
      const parentRow = $(this).parent();
      const title = parentRow.find('.steps-widget__title');
      const content = parentRow.find('.steps-widget__hide-content');
      const initRowHeight = parentRow
        .find('.steps-widget__image')
        .outerHeight(true);
      // Calculate the modified row height
      const modifyRowHeight = content.outerHeight(true) + 150;
      parentRow.css('height', initRowHeight);

      parentRow.toggleClass('active');

      if (parentRow.hasClass('active')) {
        // Expand the row
        parentRow.css('height', modifyRowHeight);
      } else {
        // Collapse the row
        parentRow.css('height', initRowHeight);
      }

      // Toggle the visibility of the title and content with a fade effect
      title.fadeToggle(500);
      content.fadeToggle(500);
    });
  });
}
